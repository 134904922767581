<template>
  <v-card :class="['pa-4', 'scroll-bar']" :height="height" @click="$emit('click')">
    <div>
      <div class="d-flex align-center">
        <v-avatar size="50" :color="avatarColor">
          <v-icon size="35" color="white">{{ icon }}</v-icon>
        </v-avatar>
        <div class="ml-3">
          <p class="ma-0 pa-0" style="font-size: 15px">
            {{ title }}
          </p>
          <p class="ma-0 pa-0" style="font-size: 20px">
            <b>{{ amount }}</b>
          </p>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "DashboardCard",
  props: {
    title: {
      type: String,
      default: () => ""
    },
    amount: {
      type: String,
      default: () => ""
    },
    icon: {
      type: String,
      default: () => ""
    },
    avatarColor: {
      type: String,
      default: () => ""
    },
    width: {
      type: String,
      default: () => ""
    },
    height: {
      type: String,
      default: () => ""
    }
  }
};
</script>

<style>
.action-text {
  text-align: right;
  font-size: 13px;
}
</style>
