<template>
  <div class="ma-4">
    <div>
      <v-row class="ma-2">
        <v-col class="pa-0" align="center">
          <v-img :src="require(`../assets/${getLogoName()}`)" :contain="true" style="max-width: 250px; max-height: 250px;"></v-img>
        </v-col>
      </v-row>

      <!-- Rows with route cards -->
      <div v-for="(row, index) in routeRows" :key="`row-${index}`">
        <div class="d-flex mt-5">
          <dashboard-card
            v-for="card in row"
            :key="card.title"
            width="100%"
            height="100px"
            style="flex: 1; min-width: 250px"
            :icon="card.icon"
            :title="card.title"
            :amount="card.amount"
            :avatarColor="card.avatarColor"
            class="mr-2"
            @click="goToRoute(card.route)"
          ></dashboard-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mdiCalendarMonth,
  mdiClipboardAccountOutline,
  mdiClockTimeElevenOutline,
  mdiTimetable,
  mdiAccountCogOutline,
  mdiOfficeBuildingMarker,
  mdiCalendarText,
  mdiAccountGroupOutline,
  mdiWarehouse,
  mdiDumpTruck,
  mdiImageFilterHdr,
  mdiCog
} from '@mdi/js'
import { mapState } from 'vuex'
import DashboardCard from '@/components/DashboardCard.vue'
import { translations } from '@/utils/common'
import { getLogoFilename } from '@/utils/api/config.js'

export default {
  name: 'Dashboard',
  components: {
    DashboardCard
  },

  data: () => ({
    // This is an array of arrays containing objects from the "allLinks" array
    routeRows: [],

    allLinks: [
      {
        title: translations.scheduleTitle,
        route: 'schedule',
        icon: mdiCalendarMonth,
        avatarColor: 'main',
        capability: ['Activity Planner']
      },
      {
        title: translations.backOfficeTitle,
        route: 'back-office',
        icon: mdiClipboardAccountOutline,
        avatarColor: 'main',
        capability: ['Back Office']
      },
      {
        title: translations.clockInTitle,
        route: 'clock-in',
        icon: mdiClockTimeElevenOutline,
        avatarColor: 'main',
        capability: ['Reports']
      },
      {
        title: translations.timeReportsTitle,
        route: 'time-reports',
        icon: mdiTimetable,
        avatarColor: 'main',
        capability: ['Reports', 'Back Office']
      },
      {
        title: translations.manageAccounts,
        route: 'accounts',
        icon: mdiAccountCogOutline,
        avatarColor: 'main',
        capability: ['Reports', 'Back Office']
      },
      {
        title: translations.categories,
        route: 'categories',
        icon: mdiOfficeBuildingMarker,
        avatarColor: 'main',
        capability: ['Back Office']
      },
      {
        title: translations.longTermPlanner,
        route: 'Planner',
        icon: mdiCalendarText,
        avatarColor: 'main',
        capability: ['Long Term Planner']
      },
      {
        title: translations.squads,
        route: 'Squads',
        icon: mdiAccountGroupOutline,
        avatarColor: 'main',
        capability: ['Long Term Planner']
      },
      {
        title: translations.managePiles,
        route: 'plants',
        icon: mdiImageFilterHdr,
        avatarColor: 'main',
        capability: ['Manage piles']
      },
      {
        title: translations.localUnitList,
        route: 'local-units',
        icon: mdiWarehouse,
        avatarColor: 'main',
        capability: ['Manage piles']
      },
      {
        title: translations.manufacturerAndCarrier,
        route: 'manufacturers-and-carriers',
        icon: mdiDumpTruck,
        avatarColor: 'main',
        capability: ['Manage piles']
      },
      {
        title: translations.admin,
        route: 'admin',
        icon: mdiCog,
        avatarColor: 'main',
        capability: ['Update Report', 'Recalculate Warnings']
      }
    ]
  }),

  methods: {
    goToRoute(sezione) {
      this.$router.push({ path: sezione })
    },

    initRouteRows() {
      // How many elements per route row are allowed
      const maxRouteRowSize = 3
      let currentIndex = 0

      // Dinamically create route rows based on the user's capabilities and the max row size
      if (this.userInfo?.account?.capabilities) {
        this.allLinks.forEach(link => {
          const findUserCapabilities = element => link.capability.includes(element.name)
          const hasCapability = this.userInfo.account.capabilities.some(findUserCapabilities)
          if (hasCapability) {
            // The row for the current index has not been initialized yet. Create new row with the element.
            if (!this.routeRows[currentIndex]) {
              this.routeRows.push([link])
            }
            // The row for the current index has been initialized and the limit of elements has not been reached. Push the element.
            else if (this.routeRows[currentIndex] && this.routeRows[currentIndex].length < maxRouteRowSize) {
              this.routeRows[currentIndex].push(link)
            }
            // The row for the current index has been initialized but the limit of elements has been reached. Create new row with the element.
            else if (this.routeRows[currentIndex] && this.routeRows[currentIndex].length === maxRouteRowSize) {
              currentIndex++
              this.routeRows.push([link])
            }
          }
        })
      }
    },

    getLogoName() {
      return getLogoFilename()
    }
  },

  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  },

  created() {
    this.initRouteRows()
  }
}
</script>
